import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { FormattedMessage } from "react-intl";

const SelectLanguage = (props) => {
  const links = props.langs.map((lang) => (
    <Link to={lang.link} key={lang.langKey} className="header__lang__list">
      {lang.langName}
    </Link>
  ));

  return (
    <>
      {props.is_mobile !== undefined && props.is_mobile == true ? (
        <>
          <label htmlFor="nav_lang" className="mobile--only nav__lang__trigger">
            {props.selected}
          </label>
          <input
            defaultChecked={false}
            type="checkbox"
            name="nav_lang"
            id="nav_lang"
            className="hidden"
          />
          <div className="nav__lang">{links}</div>
        </>
      ) : (
        <div className="header__lang">
          <input type="checkbox" name="lang" id="lang"></input>

          <label className="header__lang__title" htmlFor="lang">
            {props.selected}
          </label>
          <div className="header__lang__content">{links}</div>
        </div>
      )}
    </>
  );
};

SelectLanguage.propTypes = {
  langs: PropTypes.array,
};

export default SelectLanguage;
