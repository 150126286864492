import PropTypes from "prop-types";
import React from "react";
import { Link } from "gatsby";

const Footer = ({ home_link, content }) => (
  <footer>
    <div className="container">
      <div className="footer--left">
        © {new Date().getFullYear()} AYANA HOSPITALITY. ALL RIGHTS RESERVED |{" "}
        <Link
          to={home_link + "terms-and-conditions-8"}
          style={{ color: "#fff" }}>
          {content.footer_link_tos}
        </Link>
      </div>
    </div>
  </footer>
);

Footer.propTypes = {
  props: PropTypes.string,
};

Footer.defaultProps = {
  props: ``,
};

export default Footer;
