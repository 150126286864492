import { useMemo } from "react";
import { useLocation } from "@reach/router";

export function useQueryParam(params) {
  const location = useLocation();

  const paramValues = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const obj = {};

    params.forEach((param) => {
      obj[param] = searchParams.get(param);
    });

    return obj;
  }, [location.search, params]);

  return paramValues;
}
