module.exports = {
    phone_code : [
        {
          "id": "af",
          "code": "93",
          "name": "Afghanistan (‫افغانستان‬‎)"
        },
        {
          "id": "al",
          "code": "355",
          "name": "Albania (Shqipëri)"
        },
        {
          "id": "dz",
          "code": "213",
          "name": "Algeria (‫الجزائر‬‎)"
        },
        {
          "id": "as",
          "code": "1684",
          "name": "American Samoa"
        },
        {
          "id": "ad",
          "code": "376",
          "name": "Andorra"
        },
        {
          "id": "ao",
          "code": "244",
          "name": "Angola"
        },
        {
          "id": "ai",
          "code": "1264",
          "name": "Anguilla"
        },
        {
          "id": "ag",
          "code": "1268",
          "name": "Antigua and Barbuda"
        },
        {
          "id": "ar",
          "code": "54",
          "name": "Argentina"
        },
        {
          "id": "am",
          "code": "374",
          "name": "Armenia (Հայաստան)"
        },
        {
          "id": "aw",
          "code": "297",
          "name": "Aruba"
        },
        {
          "id": "au",
          "code": "61",
          "name": "Australia"
        },
        {
          "id": "at",
          "code": "43",
          "name": "Austria (Österreich)"
        },
        {
          "id": "az",
          "code": "994",
          "name": "Azerbaijan (Azərbaycan)"
        },
        {
          "id": "bs",
          "code": "1242",
          "name": "Bahamas"
        },
        {
          "id": "bh",
          "code": "973",
          "name": "Bahrain (‫البحرين‬‎)"
        },
        {
          "id": "bd",
          "code": "880",
          "name": "Bangladesh (বাংলাদেশ)"
        },
        {
          "id": "bb",
          "code": "1246",
          "name": "Barbados"
        },
        {
          "id": "by",
          "code": "375",
          "name": "Belarus (Беларусь)"
        },
        {
          "id": "be",
          "code": "32",
          "name": "Belgium (België)"
        },
        {
          "id": "bz",
          "code": "501",
          "name": "Belize"
        },
        {
          "id": "bj",
          "code": "229",
          "name": "Benin (Bénin)"
        },
        {
          "id": "bm",
          "code": "1441",
          "name": "Bermuda"
        },
        {
          "id": "bt",
          "code": "975",
          "name": "Bhutan (འབྲུག)"
        },
        {
          "id": "bo",
          "code": "591",
          "name": "Bolivia"
        },
        {
          "id": "ba",
          "code": "387",
          "name": "Bosnia and Herzegovina (Босна и Херцеговина)"
        },
        {
          "id": "bw",
          "code": "267",
          "name": "Botswana"
        },
        {
          "id": "br",
          "code": "55",
          "name": "Brazil (Brasil)"
        },
        {
          "id": "io",
          "code": "246",
          "name": "British Indian Ocean Territory"
        },
        {
          "id": "vg",
          "code": "1284",
          "name": "British Virgin Islands"
        },
        {
          "id": "bn",
          "code": "673",
          "name": "Brunei"
        },
        {
          "id": "bg",
          "code": "359",
          "name": "Bulgaria (България)"
        },
        {
          "id": "bf",
          "code": "226",
          "name": "Burkina Faso"
        },
        {
          "id": "bi",
          "code": "257",
          "name": "Burundi (Uburundi)"
        },
        {
          "id": "kh",
          "code": "855",
          "name": "Cambodia (កម្ពុជា)"
        },
        {
          "id": "cm",
          "code": "237",
          "name": "Cameroon (Cameroun)"
        },
        {
          "id": "ca",
          "code": "1",
          "name": "Canada"
        },
        {
          "id": "cv",
          "code": "238",
          "name": "Cape Verde (Kabu Verdi)"
        },
        {
          "id": "bq",
          "code": "599",
          "name": "Caribbean Netherlands"
        },
        {
          "id": "ky",
          "code": "1345",
          "name": "Cayman Islands"
        },
        {
          "id": "cf",
          "code": "236",
          "name": "Central African Republic (République centrafricaine)"
        },
        {
          "id": "td",
          "code": "235",
          "name": "Chad (Tchad)"
        },
        {
          "id": "cl",
          "code": "56",
          "name": "Chile"
        },
        {
          "id": "cn",
          "code": "86",
          "name": "China (中国)"
        },
        {
          "id": "cx",
          "code": "61",
          "name": "Christmas Island"
        },
        {
          "id": "cc",
          "code": "61",
          "name": "Cocos (Keeling) Islands"
        },
        {
          "id": "co",
          "code": "57",
          "name": "Colombia"
        },
        {
          "id": "km",
          "code": "269",
          "name": "Comoros (‫جزر القمر‬‎)"
        },
        {
          "id": "cd",
          "code": "243",
          "name": "Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)"
        },
        {
          "id": "cg",
          "code": "242",
          "name": "Congo (Republic) (Congo-Brazzaville)"
        },
        {
          "id": "ck",
          "code": "682",
          "name": "Cook Islands"
        },
        {
          "id": "cr",
          "code": "506",
          "name": "Costa Rica"
        },
        {
          "id": "ci",
          "code": "225",
          "name": "Côte d’Ivoire"
        },
        {
          "id": "hr",
          "code": "385",
          "name": "Croatia (Hrvatska)"
        },
        {
          "id": "cu",
          "code": "53",
          "name": "Cuba"
        },
        {
          "id": "cw",
          "code": "599",
          "name": "Curaçao"
        },
        {
          "id": "cy",
          "code": "357",
          "name": "Cyprus (Κύπρος)"
        },
        {
          "id": "cz",
          "code": "420",
          "name": "Czech Republic (Česká republika)"
        },
        {
          "id": "dk",
          "code": "45",
          "name": "Denmark (Danmark)"
        },
        {
          "id": "dj",
          "code": "253",
          "name": "Djibouti"
        },
        {
          "id": "dm",
          "code": "1767",
          "name": "Dominica"
        },
        {
          "id": "do",
          "code": "1",
          "name": "Dominican Republic (República Dominicana)"
        },
        {
          "id": "ec",
          "code": "593",
          "name": "Ecuador"
        },
        {
          "id": "eg",
          "code": "20",
          "name": "Egypt (‫مصر‬‎)"
        },
        {
          "id": "sv",
          "code": "503",
          "name": "El Salvador"
        },
        {
          "id": "gq",
          "code": "240",
          "name": "Equatorial Guinea (Guinea Ecuatorial)"
        },
        {
          "id": "er",
          "code": "291",
          "name": "Eritrea"
        },
        {
          "id": "ee",
          "code": "372",
          "name": "Estonia (Eesti)"
        },
        {
          "id": "et",
          "code": "251",
          "name": "Ethiopia"
        },
        {
          "id": "fk",
          "code": "500",
          "name": "Falkland Islands (Islas Malvinas)"
        },
        {
          "id": "fo",
          "code": "298",
          "name": "Faroe Islands (Føroyar)"
        },
        {
          "id": "fj",
          "code": "679",
          "name": "Fiji"
        },
        {
          "id": "fi",
          "code": "358",
          "name": "Finland (Suomi)"
        },
        {
          "id": "fr",
          "code": "33",
          "name": "France"
        },
        {
          "id": "gf",
          "code": "594",
          "name": "French Guiana (Guyane française)"
        },
        {
          "id": "pf",
          "code": "689",
          "name": "French Polynesia (Polynésie française)"
        },
        {
          "id": "ga",
          "code": "241",
          "name": "Gabon"
        },
        {
          "id": "gm",
          "code": "220",
          "name": "Gambia"
        },
        {
          "id": "ge",
          "code": "995",
          "name": "Georgia (საქართველო)"
        },
        {
          "id": "de",
          "code": "49",
          "name": "Germany (Deutschland)"
        },
        {
          "id": "gh",
          "code": "233",
          "name": "Ghana (Gaana)"
        },
        {
          "id": "gi",
          "code": "350",
          "name": "Gibraltar"
        },
        {
          "id": "gr",
          "code": "30",
          "name": "Greece (Ελλάδα)"
        },
        {
          "id": "gl",
          "code": "299",
          "name": "Greenland (Kalaallit Nunaat)"
        },
        {
          "id": "gd",
          "code": "1473",
          "name": "Grenada"
        },
        {
          "id": "gp",
          "code": "590",
          "name": "Guadeloupe"
        },
        {
          "id": "gu",
          "code": "1671",
          "name": "Guam"
        },
        {
          "id": "gt",
          "code": "502",
          "name": "Guatemala"
        },
        {
          "id": "gg",
          "code": "44",
          "name": "Guernsey"
        },
        {
          "id": "gn",
          "code": "224",
          "name": "Guinea (Guinée)"
        },
        {
          "id": "gw",
          "code": "245",
          "name": "Guinea-Bissau (Guiné Bissau)"
        },
        {
          "id": "gy",
          "code": "592",
          "name": "Guyana"
        },
        {
          "id": "ht",
          "code": "509",
          "name": "Haiti"
        },
        {
          "id": "hn",
          "code": "504",
          "name": "Honduras"
        },
        {
          "id": "hk",
          "code": "852",
          "name": "Hong Kong (香港)"
        },
        {
          "id": "hu",
          "code": "36",
          "name": "Hungary (Magyarország)"
        },
        {
          "id": "is",
          "code": "354",
          "name": "Iceland (Ísland)"
        },
        {
          "id": "in",
          "code": "91",
          "name": "India (भारत)"
        },
        {
          "id": "id",
          "code": "62",
          "name": "Indonesia"
        },
        {
          "id": "ir",
          "code": "98",
          "name": "Iran (‫ایران‬‎)"
        },
        {
          "id": "iq",
          "code": "964",
          "name": "Iraq (‫العراق‬‎)"
        },
        {
          "id": "ie",
          "code": "353",
          "name": "Ireland"
        },
        {
          "id": "im",
          "code": "44",
          "name": "Isle of Man"
        },
        {
          "id": "il",
          "code": "972",
          "name": "Israel (‫ישראל‬‎)"
        },
        {
          "id": "it",
          "code": "39",
          "name": "Italy (Italia)"
        },
        {
          "id": "jm",
          "code": "1876",
          "name": "Jamaica"
        },
        {
          "id": "jp",
          "code": "81",
          "name": "Japan (日本)"
        },
        {
          "id": "je",
          "code": "44",
          "name": "Jersey"
        },
        {
          "id": "jo",
          "code": "962",
          "name": "Jordan (‫الأردن‬‎)"
        },
        {
          "id": "kz",
          "code": "7",
          "name": "Kazakhstan (Казахстан)"
        },
        {
          "id": "ke",
          "code": "254",
          "name": "Kenya"
        },
        {
          "id": "ki",
          "code": "686",
          "name": "Kiribati"
        },
        {
          "id": "kw",
          "code": "965",
          "name": "Kuwait (‫الكويت‬‎)"
        },
        {
          "id": "kg",
          "code": "996",
          "name": "Kyrgyzstan (Кыргызстан)"
        },
        {
          "id": "la",
          "code": "856",
          "name": "Laos (ລາວ)"
        },
        {
          "id": "lv",
          "code": "371",
          "name": "Latvia (Latvija)"
        },
        {
          "id": "lb",
          "code": "961",
          "name": "Lebanon (‫لبنان‬‎)"
        },
        {
          "id": "ls",
          "code": "266",
          "name": "Lesotho"
        },
        {
          "id": "lr",
          "code": "231",
          "name": "Liberia"
        },
        {
          "id": "ly",
          "code": "218",
          "name": "Libya (‫ليبيا‬‎)"
        },
        {
          "id": "li",
          "code": "423",
          "name": "Liechtenstein"
        },
        {
          "id": "lt",
          "code": "370",
          "name": "Lithuania (Lietuva)"
        },
        {
          "id": "lu",
          "code": "352",
          "name": "Luxembourg"
        },
        {
          "id": "mo",
          "code": "853",
          "name": "Macau (澳門)"
        },
        {
          "id": "mk",
          "code": "389",
          "name": "Macedonia (FYROM) (Македонија)"
        },
        {
          "id": "mg",
          "code": "261",
          "name": "Madagascar (Madagasikara)"
        },
        {
          "id": "mw",
          "code": "265",
          "name": "Malawi"
        },
        {
          "id": "my",
          "code": "60",
          "name": "Malaysia"
        },
        {
          "id": "mv",
          "code": "960",
          "name": "Maldives"
        },
        {
          "id": "ml",
          "code": "223",
          "name": "Mali"
        },
        {
          "id": "mt",
          "code": "356",
          "name": "Malta"
        },
        {
          "id": "mh",
          "code": "692",
          "name": "Marshall Islands"
        },
        {
          "id": "mq",
          "code": "596",
          "name": "Martinique"
        },
        {
          "id": "mr",
          "code": "222",
          "name": "Mauritania (‫موريتانيا‬‎)"
        },
        {
          "id": "mu",
          "code": "230",
          "name": "Mauritius (Moris)"
        },
        {
          "id": "yt",
          "code": "262",
          "name": "Mayotte"
        },
        {
          "id": "mx",
          "code": "52",
          "name": "Mexico (México)"
        },
        {
          "id": "fm",
          "code": "691",
          "name": "Micronesia"
        },
        {
          "id": "md",
          "code": "373",
          "name": "Moldova (Republica Moldova)"
        },
        {
          "id": "mc",
          "code": "377",
          "name": "Monaco"
        },
        {
          "id": "mn",
          "code": "976",
          "name": "Mongolia (Монгол)"
        },
        {
          "id": "me",
          "code": "382",
          "name": "Montenegro (Crna Gora)"
        },
        {
          "id": "ms",
          "code": "1664",
          "name": "Montserrat"
        },
        {
          "id": "ma",
          "code": "212",
          "name": "Morocco (‫المغرب‬‎)"
        },
        {
          "id": "mz",
          "code": "258",
          "name": "Mozambique (Moçambique)"
        },
        {
          "id": "mm",
          "code": "95",
          "name": "Myanmar (Burma) (မြန်မာ)"
        },
        {
          "id": "na",
          "code": "264",
          "name": "Namibia (Namibië)"
        },
        {
          "id": "nr",
          "code": "674",
          "name": "Nauru"
        },
        {
          "id": "np",
          "code": "977",
          "name": "Nepal (नेपाल)"
        },
        {
          "id": "nl",
          "code": "31",
          "name": "Netherlands (Nederland)"
        },
        {
          "id": "nc",
          "code": "687",
          "name": "New Caledonia (Nouvelle-Calédonie)"
        },
        {
          "id": "nz",
          "code": "64",
          "name": "New Zealand"
        },
        {
          "id": "ni",
          "code": "505",
          "name": "Nicaragua"
        },
        {
          "id": "ne",
          "code": "227",
          "name": "Niger (Nijar)"
        },
        {
          "id": "ng",
          "code": "234",
          "name": "Nigeria"
        },
        {
          "id": "nu",
          "code": "683",
          "name": "Niue"
        },
        {
          "id": "nf",
          "code": "672",
          "name": "Norfolk Island"
        },
        {
          "id": "kp",
          "code": "850",
          "name": "North Korea (조선 민주주의 인민 공화국)"
        },
        {
          "id": "mp",
          "code": "1670",
          "name": "Northern Mariana Islands"
        },
        {
          "id": "no",
          "code": "47",
          "name": "Norway (Norge)"
        },
        {
          "id": "om",
          "code": "968",
          "name": "Oman (‫عُمان‬‎)"
        },
        {
          "id": "pk",
          "code": "92",
          "name": "Pakistan (‫پاکستان‬‎)"
        },
        {
          "id": "pw",
          "code": "680",
          "name": "Palau"
        },
        {
          "id": "ps",
          "code": "970",
          "name": "Palestine (‫فلسطين‬‎)"
        },
        {
          "id": "pa",
          "code": "507",
          "name": "Panama (Panamá)"
        },
        {
          "id": "pg",
          "code": "675",
          "name": "Papua New Guinea"
        },
        {
          "id": "py",
          "code": "595",
          "name": "Paraguay"
        },
        {
          "id": "pe",
          "code": "51",
          "name": "Peru (Perú)"
        },
        {
          "id": "ph",
          "code": "63",
          "name": "Philippines"
        },
        {
          "id": "pl",
          "code": "48",
          "name": "Poland (Polska)"
        },
        {
          "id": "pt",
          "code": "351",
          "name": "Portugal"
        },
        {
          "id": "pr",
          "code": "1",
          "name": "Puerto Rico"
        },
        {
          "id": "qa",
          "code": "974",
          "name": "Qatar (‫قطر‬‎)"
        },
        {
          "id": "re",
          "code": "262",
          "name": "Réunion (La Réunion)"
        },
        {
          "id": "ro",
          "code": "40",
          "name": "Romania (România)"
        },
        {
          "id": "ru",
          "code": "7",
          "name": "Russia (Россия)"
        },
        {
          "id": "rw",
          "code": "250",
          "name": "Rwanda"
        },
        {
          "id": "bl",
          "code": "590",
          "name": "Saint Barthélemy (Saint-Barthélemy)"
        },
        {
          "id": "sh",
          "code": "290",
          "name": "Saint Helena"
        },
        {
          "id": "kn",
          "code": "1869",
          "name": "Saint Kitts and Nevis"
        },
        {
          "id": "lc",
          "code": "1758",
          "name": "Saint Lucia"
        },
        {
          "id": "mf",
          "code": "590",
          "name": "Saint Martin (Saint-Martin (partie française))"
        },
        {
          "id": "pm",
          "code": "508",
          "name": "Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)"
        },
        {
          "id": "vc",
          "code": "1784",
          "name": "Saint Vincent and the Grenadines"
        },
        {
          "id": "ws",
          "code": "685",
          "name": "Samoa"
        },
        {
          "id": "sm",
          "code": "378",
          "name": "San Marino"
        },
        {
          "id": "st",
          "code": "239",
          "name": "São Tomé and Príncipe (São Tomé e Príncipe)"
        },
        {
          "id": "sa",
          "code": "966",
          "name": "Saudi Arabia (‫المملكة العربية السعودية‬‎)"
        },
        {
          "id": "sn",
          "code": "221",
          "name": "Senegal (Sénégal)"
        },
        {
          "id": "rs",
          "code": "381",
          "name": "Serbia (Србија)"
        },
        {
          "id": "sc",
          "code": "248",
          "name": "Seychelles"
        },
        {
          "id": "sl",
          "code": "232",
          "name": "Sierra Leone"
        },
        {
          "id": "sg",
          "code": "65",
          "name": "Singapore"
        },
        {
          "id": "sx",
          "code": "1721",
          "name": "Sint Maarten"
        },
        {
          "id": "sk",
          "code": "421",
          "name": "Slovakia (Slovensko)"
        },
        {
          "id": "si",
          "code": "386",
          "name": "Slovenia (Slovenija)"
        },
        {
          "id": "sb",
          "code": "677",
          "name": "Solomon Islands"
        },
        {
          "id": "so",
          "code": "252",
          "name": "Somalia (Soomaaliya)"
        },
        {
          "id": "za",
          "code": "27",
          "name": "South Africa"
        },
        {
          "id": "kr",
          "code": "82",
          "name": "South Korea (대한민국)"
        },
        {
          "id": "ss",
          "code": "211",
          "name": "South Sudan (‫جنوب السودان‬‎)"
        },
        {
          "id": "es",
          "code": "34",
          "name": "Spain (España)"
        },
        {
          "id": "lk",
          "code": "94",
          "name": "Sri Lanka (ශ්‍රී ලංකාව)"
        },
        {
          "id": "sd",
          "code": "249",
          "name": "Sudan (‫السودان‬‎)"
        },
        {
          "id": "sr",
          "code": "597",
          "name": "Suriname"
        },
        {
          "id": "sj",
          "code": "47",
          "name": "Svalbard and Jan Mayen"
        },
        {
          "id": "sz",
          "code": "268",
          "name": "Swaziland"
        },
        {
          "id": "se",
          "code": "46",
          "name": "Sweden (Sverige)"
        },
        {
          "id": "ch",
          "code": "41",
          "name": "Switzerland (Schweiz)"
        },
        {
          "id": "sy",
          "code": "963",
          "name": "Syria (‫سوريا‬‎)"
        },
        {
          "id": "tw",
          "code": "886",
          "name": "Taiwan (台灣)"
        },
        {
          "id": "tj",
          "code": "992",
          "name": "Tajikistan"
        },
        {
          "id": "tz",
          "code": "255",
          "name": "Tanzania"
        },
        {
          "id": "th",
          "code": "66",
          "name": "Thailand (ไทย)"
        },
        {
          "id": "tl",
          "code": "670",
          "name": "Timor-Leste"
        },
        {
          "id": "tg",
          "code": "228",
          "name": "Togo"
        },
        {
          "id": "tk",
          "code": "690",
          "name": "Tokelau"
        },
        {
          "id": "to",
          "code": "676",
          "name": "Tonga"
        },
        {
          "id": "tt",
          "code": "1868",
          "name": "Trinidad and Tobago"
        },
        {
          "id": "tn",
          "code": "216",
          "name": "Tunisia (‫تونس‬‎)"
        },
        {
          "id": "tr",
          "code": "90",
          "name": "Turkey (Türkiye)"
        },
        {
          "id": "tm",
          "code": "993",
          "name": "Turkmenistan"
        },
        {
          "id": "tc",
          "code": "1649",
          "name": "Turks and Caicos Islands"
        },
        {
          "id": "tv",
          "code": "688",
          "name": "Tuvalu"
        },
        {
          "id": "vi",
          "code": "1340",
          "name": "U.S. Virgin Islands"
        },
        {
          "id": "ug",
          "code": "256",
          "name": "Uganda"
        },
        {
          "id": "ua",
          "code": "380",
          "name": "Ukraine (Україна)"
        },
        {
          "id": "ae",
          "code": "971",
          "name": "United Arab Emirates (‫الإمارات العربية المتحدة‬‎)"
        },
        {
          "id": "gb",
          "code": "44",
          "name": "United Kingdom"
        },
        {
          "id": "us",
          "code": "1",
          "name": "United States"
        },
        {
          "id": "uy",
          "code": "598",
          "name": "Uruguay"
        },
        {
          "id": "uz",
          "code": "998",
          "name": "Uzbekistan (Oʻzbekiston)"
        },
        {
          "id": "vu",
          "code": "678",
          "name": "Vanuatu"
        },
        {
          "id": "va",
          "code": "39",
          "name": "Vatican City (Città del Vaticano)"
        },
        {
          "id": "ve",
          "code": "58",
          "name": "Venezuela"
        },
        {
          "id": "vn",
          "code": "84",
          "name": "Vietnam (Việt Nam)"
        },
        {
          "id": "wf",
          "code": "681",
          "name": "Wallis and Futuna"
        },
        {
          "id": "eh",
          "code": "212",
          "name": "Western Sahara (‫الصحراء الغربية‬‎)"
        },
        {
          "id": "ye",
          "code": "967",
          "name": "Yemen (‫اليمن‬‎)"
        },
        {
          "id": "zm",
          "code": "260",
          "name": "Zambia"
        },
        {
          "id": "zw",
          "code": "263",
          "name": "Zimbabwe"
        },
        {
          "id": "ax",
          "code": "358",
          "name": "Åland Islands"
        },
        {
          "id": "aq",
          "code": "672",
          "name": "Antarctica"
        },
        {
          "id": "bv",
          "code": "47",
          "name": "Bouvet Island"
        },
        {
          "id": "gs",
          "code": "500",
          "name": "South Georgia and the South Sandwich Islands"
        },
        {
          "id": "tf",
          "code": "262",
          "name": "French Southern Territories (the)"
        },
        {
          "id": "hm",
          "code": "672",
          "name": "Heard Island and McDonald Islands"
        },
        {
          "id": "um",
          "code": "246",
          "name": "United States Minor Outlying Islands (the)"
        },
        {
          "id": "pn",
          "code": "64",
          "name": "Pitcairn"
        }
    ]
  }